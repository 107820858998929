<script>

  import Multiselect from "vue-multiselect";
  import datetime from 'vuejs-datetimepicker';
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import {
    required
  } from "vuelidate/lib/validators";


  /**
   * Advanced-form component
   */
  export default {
    page: {
      title: "Leave Edit Form",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: {
      Multiselect,
      Layout,
      PageHeader,
      datetime,
    },
    data() {
      return {
        title: "Update Leave Form",
        items: [
          {
            text: "Update Leave",
            href: "/",
          },
          {
            text: "Add",
            active: true,
          },
        ],
        leaveOptions:['Approve',"Deny"],
        value: null,
        status: '',
        submitted: false,
        leaveId: 0,
        isEdit: false,
        alertMsg: "Leave Updated Successfully!",
        disabled: true,
        disabledOwner: false,
        showDismissibleAlert: false,
        loading: false,
        leaveData: [],

        posts: {
          employeeName: "",
          startDate: "",
          endDate: "",
          reason: "",
          leaveStatus: "",
          adminReply: "",
          colorCode: '#000000',
          empType: false,
        }
      };
    },
    mounted() {
      if (this.$route.params.type == "Edit") {
        // if(this.$route.params.id){
           sessionStorage.setItem('leaveId',this.$route.params.id);
      // }
      } else {
        sessionStorage.setItem('leaveId', 0);
      }
      this.leaveId = sessionStorage.getItem('leaveId')
      if (this.leaveId !=undefined) {
        this.getLeaveID();
        this.isEdit = true;
      }
    },
    validations: {
      posts: {
        adminReply: {
          required,
        },
        leaveStatus: {
          required,
        },
      },

    },
    methods: {
      changeColor(color) {
        const { rgba: { r, g, b, a } } = color;
        this.posts.colorCode = `rgba(${r, g, b, a})`;
      },

      submitEditLeaveForm(e) {
        this.submitted = true;
        // stop here if form is invalid
        // this.$v.$touch();
        // if (this.$v.$invalid) {
        //   console.log("Something went wrong");
        //   return;
        // } else {
          this.loading = true;
          e.preventDefault();
          let apiName = "editLeave";
          let routerPush = "leaves";
          if (this.isEdit == true) {
            apiName = "updateLeave";
            this.alertMsg = "Leave Updated Successfully!"
            routerPush = "leaves";
          }
          this.axios.post(apiName, {
            leaveId:this.leaveId,
            StartDate:this.posts.startDate,
            EndDate:this.posts.endDate,
            leaveStatus:this.posts.leaveStatus,
            adminReply:this.posts.adminReply,
          })
            .then((result) => {
              this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;

              setTimeout(() => {
                this.$router.push({ name: routerPush });
              }, 2000);
            })
            .catch((error) => {
              this.loading = false;
              //console.error(error.response);
              alert(error.response.data.data);
            });
        // }
      },

      getLeaveID() {
        this.axios.post(
          "getLeaveById", {
          'leaveId': this.leaveId,
        })
          .then((result) => {
            this.isReportTableBusy = false;
            this.leaveData = result.data.data.leaveData[0];
            this.posts.employeeName=this.leaveData.EmployeeName;
            this.posts.startDate=this.leaveData.StartDate;
            this.posts.endDate=this.leaveData.EndDate;
            this.posts.reason=this.leaveData.Reason;
            this.posts.leaveStatus=this.leaveData.leaveStatus;
            this.posts.adminReply=this.leaveData.AdminReply;
          }); 
      },
    },
    middleware: "authentication",
  };
  // 
</script>

<template lang="en">
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        {{alertMsg}}
      </b-alert>
    </div>


    <form @submit.prevent="submitEditLeaveForm" method="post">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label" for="formrow-employeeName-input">Employee Name </label>
                  <input type="text" class="form-control" disabled id="formrow-employeeName-input" name=" employeeName" v-model="posts.employeeName"
                    :class="{
                            'is-invalid': submitted && $v.posts.employeeName.$error,
                          }" >
                </div>
                <div class="mb-3 col-md-6">
                  <label>Reason</label>
                  <input type="text" class="form-control" disabled id="formrow-reason-input" name="reason" :class="{
                            'is-invalid': submitted && $v.posts.reason.$error,
                          }" v-model="posts.reason">
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-3">
                  <label class="form-label" for="formrow-startDate">Start Date</label>
                  <div class="input-group">
                            <datetime class="form-control" format="YYYY-MM-DD" v-model='posts.startDate' name="startDate" :class="{
                              'is-invalid': submitted && $v.posts.startDate.$error,
                            }" ></datetime>
                    
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="form-label" for="formrow-endDate">End Date</label>
                  <div class="input-group">
                            <datetime class="form-control" format="YYYY-MM-DD" v-model='posts.endDate' name="endDate" ></datetime>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label class="mt-3">Leave Status*</label>
                  <multiselect id="leaveStatus" name="leaveStatus" v-model="posts.leaveStatus" :options="leaveOptions" :multiple="false" :allow-empty="false"></multiselect>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="mt-3">Admin Reply</label>
                  <input type="text" class="form-control" name="adminReply" id="formrow-adminReply-input" 
                     v-model="posts.adminReply" :class="{
                    'is-invalid': submitted && $v.posts.adminReply.$error,
                  }">
                  <div v-if="submitted && $v.posts.adminReply.$error" class="invalid-feedback">
                    <span v-if="!$v.posts.adminReply.required">This value is required.</span>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" class="col-3 m-2 btn btn-themeYellow w-md waves-effect waves-light right"
              :disabled="loading">Update</button>
          </div>
          </div>
        </div>
      </div>
    </form>

  </Layout>
</template>
<!-- SPECIFIC CSS -->
<style scoped src="../../../assets/css/listing.css"></style>
<style scoped src="../../../assets/css/detail-page.css"></style>

<style lang="scss" scoped>
  img.preview {
    width: 100px;
    height: 100px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
    object-fit: contain;
    margin-top: 6px;
  }

  .file-upload-form {
    padding: 5px 10px;
    background: #ececec;
    border: 1px solid #ececec;
    position: relative;
    color: black;
    border-radius: 2px;
    text-align: center;
    float: left;
    cursor: pointer;
  }

  .uploadInput {
    position: absolute;
    z-index: 1000;
    opacity: 0;
    cursor: pointer;
    right: 0;
    top: 0;
    height: 100%;
    font-size: 24px;
    width: 100%;
  }

  .alertMsg {
    position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
  }


  // card css////
  .cateresTitle {
    font-size: 16px;
    color: #222;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .cateresMenuType {
    margin-bottom: 5px;
    font-size: 14px;
    color: #777;
  }


  .bannerImg {
    width: 270px;
    height: 160px;
    object-fit: cover;
    // box-shadow: aliceblue;
    // box-shadow: 2px 4px 12px -4px #7b7b7bc4;
  }

  .foodType {
    border-radius: 13px;
    width: 25px;
    height: 25px;
    color: #fff;
    padding-top: 6px;
    display: inline-block;
    font-size: 10px;
    margin-right: 2px;
    text-align: center;
  }

  .ribbonFoodType {
    color: #fff;
    display: inline-block;
    position: absolute;
    bottom: 5px;
    left: 7px;
    z-index: 9;
  }
</style>